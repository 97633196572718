import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import colors from "../styles/colors"
import dimensions from "../styles/dimensions"
import Button from "../components/_ui/Button"
import About from "../components/About"
import Layout from "../components/Layout"
import ProjectCard from "../components/ProjectCard"
import ReactImage from "../components/_ui/ReactImage"
import NodeImage from "../components/_ui/NodeImage"
import TypescriptImage from "../components/_ui/TypescriptImage"
import MongoImage from "../components/_ui/MongoImage"
import AwsImage from "../components/_ui/AwsImage"
import WordpressImage from "../components/_ui/WordpressImage"

import { RichText } from "prismic-reactjs"

const CardContainer = styled("div")`
  display: grid;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 25px;
  padding-left: 17px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

const CardBox = styled("div")`
  grid-row: 1;
  grid-column: auto;

  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 9px 24px rgb(0 0 0 / 6%);
  color: #253b56;
  margin: 10px 10px 0 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  svg {
    height: 2.5em;
    margin-bottom: 12px;
  }
  p {
    margin: 8px 0 0 0;
    font-weight: 400;
    line-height: 1;
    margin-top: auto;
    text-align: center;
  }
`

const Hero = styled("div")`
  padding-top: 2.5em;
  padding-bottom: 3em;
  margin-bottom: 6em;
  max-width: 830px;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 3em;
  }

  h1 {
    margin-bottom: 0.8em;

    a {
      text-decoration: none;
      transition: all 100ms ease-in-out;

      &:nth-of-type(1) {
        color: ${colors.blue500};
      }
      &:nth-of-type(2) {
        color: ${colors.orange500};
      }
      &:nth-of-type(3) {
        color: ${colors.purple500};
      }
      &:nth-of-type(4) {
        color: ${colors.green500};
      }
      &:nth-of-type(5) {
        color: ${colors.teal500};
      }

      &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;

        &:nth-of-type(1) {
          color: ${colors.blue600};
          background-color: ${colors.blue200};
        }
        &:nth-of-type(2) {
          color: ${colors.orange600};
          background-color: ${colors.orange200};
        }
        &:nth-of-type(3) {
          color: ${colors.purple600};
          background-color: ${colors.purple200};
        }
        &:nth-of-type(4) {
          color: ${colors.green600};
          background-color: ${colors.green200};
        }
        &:nth-of-type(5) {
          color: ${colors.teal600};
          background-color: ${colors.teal200};
        }
      }
    }
  }
  h3 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 1.7em;
  }
`

const Section = styled("div")`
  margin-bottom: 10em;
  display: flex;
  flex-direction: column;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 4em;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const WorkAction = styled(Link)`
  font-weight: 600;
  text-decoration: none;
  color: currentColor;
  transition: all 150ms ease-in-out;
  margin-left: auto;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin: 0 auto;
  }

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }

  &:hover {
    color: ${colors.omind};
    transition: all 150ms ease-in-out;

    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`

const RenderBody = ({ home, projects, meta, data }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Hero>
      <>{RichText.render(home.hero_title.richText)}</>
      <h3>
        Solucionamos problemas. Creamos experiencias. Desarrollamos emociones.
        Diseñamos inspiración. Construimos ideas.
      </h3>
      <a
        href={home.hero_button_link.url}
        target="_blank"
        rel="noopener noreferrer"
        title="Escríbenos ahora"
      >
        <Button>{RichText.render(home.hero_button_text.richText)}</Button>
      </a>
    </Hero>
    <Section id="proyectos">
      {projects.map((project, i) => (
        <ProjectCard
          key={i}
          category={project.node.data.project_category}
          title={project.node.data.project_title}
          description={project.node.data.project_preview_description}
          thumbnail={project.node.data.project_preview_thumbnail}
          uid={project.node.uid}
        />
      ))}
      <WorkAction title="Ver más trabajos" to={"/proyectos"}>
        Ver más trabajos <span>&#8594;</span>
      </WorkAction>
    </Section>
    <Section>
      <h2>Algunas de las tecnologías que desarrollamos</h2>
      <CardContainer>
        <CardBox>
          <ReactImage />
          <p>React</p>
        </CardBox>
        <CardBox>
          <NodeImage />
          <p>Node</p>
        </CardBox>
        <CardBox>
          <TypescriptImage />
          <p>Typescript</p>
        </CardBox>
        <CardBox>
          <MongoImage />
          <p>MongoDB</p>
        </CardBox>
        <CardBox>
          <AwsImage />
          <p>AWS</p>
        </CardBox>
        <CardBox>
          <WordpressImage />
          <p>Wordpress</p>
        </CardBox>
      </CardContainer>
    </Section>
    <Section>
      {RichText.render(home.about_title.richText)}
      <About bio={home.about_bio} socialLinks={home.about_links} />
    </Section>
  </>
)

const component = ({ data }) => {
  //Required check for no data being returned
  const doc = data.allPrismicHomepage.edges.slice(0, 1).pop()
  const projects = data.allPrismicProject.edges
  const meta = data.site.siteMetadata
  if (!doc || !projects) return null

  return (
    <Layout>
      <RenderBody
        data={data}
        home={doc.node.data}
        projects={projects}
        meta={meta}
      />
    </Layout>
  )
}

export default component

RenderBody.propTypes = {
  home: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          data {
            hero_title {
              richText
            }
            hero_button_text {
              richText
            }
            hero_button_link {
              url
            }
            content {
              richText
            }
            about_title {
              richText
            }
            about_bio {
              richText
            }
            about_links {
              about_link {
                raw
                text
              }
            }
          }
        }
      }
    }
    allPrismicProject {
      edges {
        node {
          data {
            project_title {
              text
            }
            project_preview_description {
              richText
            }
            project_preview_thumbnail {
              url
            }
            project_category {
              text
            }
            project_post_date
            project_hero_image {
              url
            }
            project_description {
              richText
            }
          }
          uid
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
