import React from "react";

const AwsImage = () => (
    <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <title>ic-technology-wordpress</title>
    <desc>Created with Sketch.</desc>
    <g fill="none" fill-rule="evenodd">
    <g fill="#252B33" fill-rule="nonzero">
    <path d="m24 0c-13.234 0-24 10.766-24 24s10.766 24 24 24 24-10.766 24-24-10.766-24-24-24zm0 1.126c12.626 0 22.876 10.25 22.876 22.874 0 12.626-10.25 22.876-22.876 22.876s-22.876-10.25-22.876-22.876c2e-3 -12.626 10.252-22.876 22.876-22.876v2e-3zm0 2.3119c-6.924-0.017769-13.389 3.4614-17.188 9.2501 0.484 0.016 0.922 0 1.314 0 2.156 0 5.5-0.25 5.5-0.25 1.108-0.062 1.234 1.554 0.124 1.688 0 0-1.132 0.186-2.376 0.248l7.5 22.252 4.5-13.5-3.186-8.752c-1.11-0.06-2.188-0.248-2.188-0.248-1.11-0.064-0.984-1.752 0.126-1.688 0 0 3.414 0.25 5.436 0.25 2.156 0 5.5-0.25 5.5-0.25 1.11-0.062 1.234 1.554 0.126 1.688 0 0-1.14 0.186-2.376 0.248l7.438 22.126 2.062-6.876c1.04-2.67 1.564-4.92 1.564-6.686 0-2.548-0.9-4.26-1.688-5.626-1.04-1.694-2-3.18-2-4.874 0-1.892 1.414-3.626 3.436-3.626h0.25c-3.7837-3.473-8.7381-5.3913-13.874-5.372v-0.0020683zm18.062 10.686c0.094 0.658 0.126 1.368 0.126 2.126 0 2.086-0.392 4.438-1.564 7.376l-6.25 18.186c6.3293-3.6961 10.212-10.483 10.19-17.812 0-3.58-0.89009-6.946-2.5001-9.876h-2e-3zm-36.874 1.5c-1.1666 2.6376-1.763 5.4919-1.75 8.376 0 8.148 4.694 15.164 11.562 18.5l-9.812-26.876zm19.186 10.188-6.186 17.938c1.844 0.54 3.788 0.812 5.812 0.812 2.4 0 4.672-0.374 6.812-1.124-0.054-0.086-0.086-0.212-0.124-0.314l-6.314-17.312z"/>
    </g>
    </g>
    </svg>
);

export default AwsImage;
