import React from "react";

const AwsImage = () => (
    <svg version="1.1" viewBox="0 0 60 48" xmlns="http://www.w3.org/2000/svg">
    <title>ic-technology-aws</title>
    <desc>Created with Sketch.</desc>
    <g fill="none" fill-rule="evenodd">
    <g fill="#252B33" fill-rule="nonzero">
    <path d="m17.506 30.103-5.4945 2.3602 5.0886 2.1761 5.9005-2.1761-5.4945-2.3602zm-8.2607 2.9502-0.18411 10.621 8.0388 3.5403v-11.027l-7.8547-3.1344zm16.521 0-7.2647 2.7661v10.399l7.2647-2.9502v-10.215zm8.6666-33.043-5.5323 2.3602 5.1263 2.1761 5.9005-2.1761-5.4945-2.3602zm-7.6706 3.1356v10.621l6.8587 1.992 0.22183-9.6626-7.0806-2.9502zm15.341 0.58999-6.4906 2.7662v10.437l6.4906-2.9502v-10.253zm-33.652 11.223-5.4945 2.3602 5.0886 2.1761 5.9005-2.1761-5.4945-2.3602zm-8.2607 2.9502-0.18411 10.621 8.0388 3.5403v-11.027l-7.8547-3.1343zm16.521 0-7.2647 2.7661v10.399l7.2647-2.9502v-10.215zm9.4407-3.3421-5.4945 2.3602 5.0886 2.1761 5.9005-2.1761-5.4945-2.3602zm-8.2607 2.9503-0.18411 10.621 8.0388 3.5403v-11.026l-7.8547-3.1355zm16.521 0-7.2647 2.7661v10.399l7.2647-2.9502v-10.215zm17.308-17.507-5.4945 2.3602 5.0886 2.1761 5.9004-2.1761-5.4945-2.3602zm-8.2607 2.9502-0.18405 10.621 8.0388 3.5403v-11.026l-7.8548-3.1355zm16.521 0-7.2647 2.7661v10.399l7.2647-2.9502v-10.215z"/>
    </g>
    </g>
    </svg>
);

export default AwsImage;
