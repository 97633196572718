import React from "react";

const TypescriptImage = () => (
    <svg version="1.1" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
    <title>ic-technology-typescript</title>
    <desc>Created with Sketch.</desc>
    <g fill="none" fill-rule="evenodd">
    <g fill="#252B33" fill-rule="nonzero">
    <path d="m45 0v45h-45v-45h45zm-8.82 20.678c-0.77625-0.2025-2.6325-0.27-3.42-0.1125-2.43 0.45-4.1288 2.025-4.6125 4.275-0.1575 0.64125-0.10125 2.2388 0.0675 2.8912 0.225 0.75375 0.70875 1.665 1.2375 2.25 0.9225 0.9675 1.9125 1.5975 4.2412 2.5875 2.025 0.9 2.745 1.305 3.105 1.8 0.25875 0.39375 0.3375 0.63 0.3375 1.1475 0 0.5625-0.18 0.9675-0.59625 1.35-0.9675 0.8775-2.925 0.97875-4.3875 0.225-0.48375-0.27-1.3162-1.0575-1.6875-1.6425l-0.2925-0.39375-1.2712 0.7425-1.6875 0.97875-0.4275 0.27c-0.045 0.07875 0.75375 1.2825 1.17 1.7325 1.0575 1.1588 2.7788 2.0588 4.5788 2.3962 0.84375 0.14625 2.6438 0.16875 3.4312 0.03375 2.5088-0.41625 4.2638-1.6875 4.9838-3.5662 0.64125-1.7212 0.4275-4.0162-0.52875-5.4788-0.84375-1.305-2.2388-2.2162-5.4562-3.6-1.7438-0.765-2.3062-1.1362-2.61-1.755-0.135-0.2925-0.2025-0.495-0.2025-0.855 0-1.1812 0.9-1.89 2.25-1.8 0.93375 0.0675 1.53 0.4275 2.115 1.26 0.18 0.2925 0.36 0.48375 0.405 0.45 1.1812-0.73125 3.1275-2.0475 3.1275-2.115-0.045-0.2025-0.66375-0.99-1.08-1.44-0.7875-0.82125-1.665-1.3388-2.8125-1.6088zm-18.405 0.03375-7.875 0.03375v1.845l-0.03375-0.01125v1.8338h5.85v16.65h4.1512v-16.65h5.85l-2.966e-4 -2.3454c-0.0012854-0.74703-0.0081409-1.2926-0.044703-1.3109 0-0.03375-3.5662-0.045-7.8975-0.045z"/>
    </g>
    </g>
    </svg>
);

export default TypescriptImage;
