import React from "react";

const MongoImage = () => (
    <svg version="1.1" viewBox="0 0 18 40" xmlns="http://www.w3.org/2000/svg">
    <title>ic-technology-mango-db</title>
    <desc>Created with Sketch.</desc>
    <g fill="none" fill-rule="evenodd">
    <g fill="#252B33" fill-rule="nonzero">
    <path d="m17.925 17.74c-0.12465-1.6116-0.50398-3.1656-1.0315-4.688-1.0929-3.3994-2.8467-6.4079-5.3171-8.9966-0.62458-0.65472-1.3192-1.2546-1.7548-2.0695-0.27557-0.51543-0.53801-1.0379-0.8065-1.5574l-0.17013-0.4283c-0.028635 0.098242-0.046827 0.13323-0.047838 0.16856-0.021898 0.84683-0.5023 1.4211-1.1006 1.9571-0.67276 0.60325-1.299 1.258-1.9455 1.8902-0.66299 0.87072-1.3256 1.7414-1.988 2.6118-0.53632 0.9841-1.072 1.9682-1.6076 2.9523l-1.0747 2.8992-0.032341 0.045084c-0.59157 1.9406-0.88331 3.9357-1.0268 5.951-0.053902 0.75734 0.0074115 1.5258 0.050196 2.2872 0.060976 1.0884 0.25031 2.1583 0.5343 3.2114 1.0315 3.8261 3.1249 6.9997 5.9868 9.696 0.53194 0.50097 1.0777 0.95651 1.6329 1.4333 0.095002-0.32467 0.1708-0.64934 0.25704-0.97401 0.086243-0.32332 0.1735-0.64497 0.23178-0.9693-0.058618 0.32433-0.12431 0.64598-0.18933 0.9693l-0.27827 0.97401 0.24862 0.84145 0.23043 1.3068 0.10982 1.3636c-0.0010107 0.27689-0.012128 0.55446 0.0047164 0.83102 0.0040426 0.070654 0.097023 0.13592 0.1489 0.20389l0.46355 0.1625 0.48309 0.18774-0.082874-1.2122-0.0037057-1.1937 0.16676-1.8185 0.12094-0.396 0.34598-0.61233c0.42717-0.34317 0.88567-0.65472 1.2748-1.0366 0.70409-0.69106 1.4065-1.3909 2.0365-2.1475 0.81762-0.98141 1.521-2.0527 2.0857-3.2016 0.37798-0.76844 0.73374-1.5524 1.0282-2.3555 0.2594-0.70788 0.4201-1.4518 0.62458-2.1798 0.018192-0.052486 0.042448-0.10396 0.053902-0.15746 0.42245-1.9652 0.49354-3.9502 0.33823-5.949zm-7.9502 16.612-0.27119-0.59315 0.27119 0.59315 0.39854 0.33981-0.39854-0.33981z"/>
    </g>
    </g>
    </svg>
);

export default MongoImage;
